<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <div class="login-container">
    <v-card class="login-card">
      <div class="logo-image-container">
        <img class="logo-image" src="/static/unstruct-logo-new.png" alt="UnStruct Logo" />
      </div>
      <div class="bold-title">UnStruct</div>
      <div class="logo-text-subheader">
        One click shows you the truth behind every claim
      </div>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-btn block color="primary" :loading="oauthLoading" @click="oauthLogin">
                <v-icon left>mdi-google</v-icon>
                Continue with Google
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="mt-4">
            <v-col cols="12">
              <div class="text-center">or</div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn block color="primary" @click="showEmailLogin = !showEmailLogin">
                <v-icon left>mdi-email-outline</v-icon>
                Continue with Email
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-expand-transition>
        <div v-if="showEmailLogin">
          <v-card-text>
            <ValidationObserver v-slot="{ invalid, validated }">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <ValidationProvider name="Email" rules="required|email" immediate>
                      <v-text-field
                        v-model="email"
                        label="Email"
                        slot-scope="{ errors, valid }"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn text block color="success" @click="toggleCodeEntry">
                      {{ haveCode ? "Request new code" : "I already have a code" }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="!haveCode && !codeSent">
                  <v-col cols="12">
                    <v-btn block text color="success" :loading="loading" :disabled="invalid || !validated" @click="handleSendVerificationCode">
                      Send Verification Code
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="haveCode || codeSent">
                  <v-col cols="12">
                    <ValidationProvider name="Verification Code" rules="required" immediate>
                      <v-text-field
                        v-model="verificationCode"
                        label="Verification Code"
                        slot-scope="{ errors, valid }"
                        :error-messages="errors"
                        :success="valid"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col cols="12">
                    <v-btn text block color="success" :loading="loading" :disabled="invalid || !validated" @click="handleVerifyCode">
                      Verify and Login
                    </v-btn>
                  </v-col>
                  <v-col v-if="!haveCode" cols="12" class="text-center">
                    <v-btn block text color="success" :disabled="resendDisabled" @click="handleResendCode">
                      Resend Code {{ resendCountdown > 0 ? `(${resendCountdown}s)` : '' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </ValidationObserver>
          </v-card-text>
        </div>
      </v-expand-transition>

      <v-card-actions class="button-container">
        <v-row>
          <v-col cols="12" class="text-center">
            <span class="caption">
              By signing in, you agree to our
              <a href="https://ecsp.unstruct.ai/default/legal/terms-of-service" target="_blank">Terms of Service</a> and
              <a href="https://ecsp.unstruct.ai/default/legal/privacy-policy" target="_blank">Privacy Policy</a>
            </span>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex"
import { mapFields } from "vuex-map-fields"
import { ValidationObserver, ValidationProvider, extend } from "vee-validate"
import { required, email } from "vee-validate/dist/rules"

extend("email", email)
extend("required", {
  ...required,
  message: "This field is required",
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: "",
      verificationCode: "",
      showEmailLogin: false,
      codeSent: false,
      haveCode: false,
      resendCountdown: 0,
      resendInterval: null,
    }
  },
  computed: {
    ...mapFields("auth", ["loading", "oauthLoading"]),
    resendDisabled() {
      return this.resendCountdown > 0;
    },
  },
  methods: {
    ...mapActions("auth", ["oauthLogin", "sendVerificationCode", "verifyCodeAndLogin"]),
    toggleCodeEntry() {
      this.haveCode = !this.haveCode;
      if (this.haveCode) {
        this.codeSent = false;
        if (this.resendInterval) {
          clearInterval(this.resendInterval);
          this.resendCountdown = 0;
        }
      }
    },
    async handleSendVerificationCode() {
      try {
        await this.sendVerificationCode(this.email)
        this.codeSent = true
        this.haveCode = false
        this.startResendCountdown()
      } catch (error) {
        // Error is handled in the action, no need to do anything here
      }
    },
    async handleVerifyCode() {
      try {
        await this.verifyCodeAndLogin({
          email: this.email,
          code: this.verificationCode,
        })
        // Redirect is handled in the action
      } catch (error) {
        // Error is handled in the action, no need to do anything here
      }
    },
    async handleResendCode() {
      if (this.resendDisabled) return;

      try {
        await this.sendVerificationCode(this.email)
        this.startResendCountdown()
      } catch (error) {
        // Error is handled in the action, no need to do anything here
      }
    },
    startResendCountdown() {
      this.resendCountdown = 60; // 60 seconds
      this.resendInterval = setInterval(() => {
        if (this.resendCountdown > 0) {
          this.resendCountdown--;
        } else {
          clearInterval(this.resendInterval);
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    if (this.resendInterval) {
      clearInterval(this.resendInterval);
    }
  }
}
</script>

<style scoped>
.theme--dark .login-container {
  background-color: #0A192F;
}

.theme--dark .login-card {
  background: linear-gradient(to right, rgba(230,240,255,0.1) 0%, rgba(230,240,255,0.2) 50%, rgba(230,240,255,0.1) 100%);
}

.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}

.login-card {
  padding: 2rem;
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  backdrop-filter: blur(10px);
}

.bold-title {
  font-family: 'Orbitron', sans-serif;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 0.5rem;
}

.logo-text-subheader {
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: 0.5px;
}

.logo-image-container {
  text-align: center;
  margin-bottom: 1.5rem;
}

.logo-image {
  width: 60px;
  filter: brightness(0.95);
  transition: filter 0.3s ease;
}

.logo-image:hover {
  filter: brightness(1.1);
}

/* Button Styles */
.v-btn {
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  text-transform: none !important;
  margin-top: 1rem !important;
  transition: all 0.3s ease !important;
  height: 48px !important;
}

.v-btn:active {
  transform: translateY(1px);
}


/* Form Fields */
.v-text-field {
  margin-top: 1.5rem !important;
}


</style>
